//INFO: Group in folder task. For sub task use prefix es SubTask.Edit.c.js
import task from "@commons/task";

var form = $("#login-form");
var userMsg = $(".user-msg", form);
var btn = $("button", form);

$(form).submit(function (e) {
    e.preventDefault();
    var t = this;
    var data = $(this).serialize();

    $(btn).prop("disabled", true);
    $(userMsg).removeClass("alert-warning").html("").hide();

    $.post(task("User.Login"), data, function (response) {
        if (response.success) {
            $(t)[0].reset();
            $(location).attr('href', response.goTo);
        } else {
            $(userMsg).addClass("alert-warning").html(response.message).fadeIn();
            $(btn).prop("disabled", false);
        }

    }, 'json').done(function () {
    }).fail(function (xhr, status, error) {
        $(btn).prop("disabled", false);
        alert(error);
    });

});